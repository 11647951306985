import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCartTotal } from '../../../redux/Cart/cart.selectors';
import { createStructuredSelector } from 'reselect';

const mapState = createStructuredSelector({
  total: selectCartTotal
});

export default function MenuCartTotals() {
  const { total } = useSelector(mapState);
  return (
    <React.Fragment>
      <div className='cart__info'>
        <p>Total: ₺ {total}</p>
        <Link to='/cart' className='theme-button'>
          Sepeti Gör
        </Link>
        {/*<Link to='/checkout' className='theme-button check__btn'>
          Ödeme
  </Link>*/}
      </div>
    </React.Fragment>
  );
}
