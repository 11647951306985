import React from 'react';
import CheckOutItem from './CheckOutItem';

export default function CheckOutList({ value }) {
  return (
    <React.Fragment>
      {value.map(item => {
        return <CheckOutItem key={item.id} item={item} value={value} />;
      })}
    </React.Fragment>
  );
}
