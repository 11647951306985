import React, { useEffect, useState } from 'react';
import Navbar from '../components/common/Navbar';
import Breadcrumb from '../components/common/Breadcrumb';
import Footer from '../components/other/Footer';

import ImageGrid from '../components/contentment/ImageGrid';
import Modal from '../components/contentment/Modal';
import referenceService from '../fetchServices/ReferenceService';
import FullPageLoader from '../components/other/Loader';

const Contentment = () => {
  const [selectedImg, setSelectedImg] = useState(null);
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const getReference = () => {};
  useEffect(() => {
    setLoading(true);
    referenceService
      .get()
      .then(res => {
        setData(res);
        setLoading(false);
      })
      .catch(err => {
      });
  }, []);
  if (loading) return <FullPageLoader loading={loading} />;
  return (
    <main className='about-page'>
      <header className='header-area'>
        <Navbar />
      </header>
      <Breadcrumb title='Memnuniyet' />
      <div style={{ marginTop: '10%', flex: 1, marginBottom: '10%' }}>
        <ImageGrid setSelectedImg={setSelectedImg}  Img={data} />
        {selectedImg && <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />}
      </div>
      <Footer />
    </main>
  );
};

export default Contentment;
