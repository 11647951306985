import React from 'react'
import CartListItem from './CartListItem';

export default function CartLists({ value }) {
    return (
        <React.Fragment>
            {value?.map(item => {
                return <CartListItem key={item.id} item={item} value={value} />;
            })}
        </React.Fragment>
    );
};


