import React, { useEffect, useState } from 'react';
import Navbar from '../components/common/Navbar';
import Breadcrumb from '../components/common/Breadcrumb';
import About from '../components/about/About';
import ServiceSection2 from '../sections/services/ServiceSection2';
import SimpleTab from '../components/tabs/SimpleTab';
import CounterUp from '../sections/CounterUp';
import Team from '../components/team/Team';
import Clients from '../sections/Clients';
import Service3clmns from '../components/services/Service3clmns';
import CtaForm from '../components/other/CtaForm';
import Footer from '../components/other/Footer';

import orderService from '../fetchServices/OrderService';
import FullPageLoader from '../components/other/Loader';
import OrderList from '../components/order.js/OrderLists';
import OrderCartHead from '../components/order.js/OrderCartHead';

export default function Order_Page() {
  const user = localStorage.getItem('USER');
  const _user = JSON.parse(user);
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState();
  const [list, setList] = useState();
  const [mainList, setMainList] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  useEffect(() => {
    setLoading(true);
    orderService
      .get(_user?._id)
      .then(response => {
        setLoading(false);
        setMainList(response);
        setList(response);
      })
      .catch(err => {
        setLoading(false);
      });
  }, []);

  if (loading) return <FullPageLoader loading={loading} />;
  return (
    <main className='about-page'>
      {/* Header */}
      <header className='header-area'>
        <Navbar />
      </header>

      {/* Breadcrumb */}
      <Breadcrumb title='Siparişlerim' />
      <section className='product-cart-wrapper'>
        <div className='container'>
          <div className='cart-table'>
            <table>
              <OrderCartHead />
              <tbody>
                <OrderList value={list} />
              </tbody>
            </table>
          </div>
        </div>
      </section>
      {/* Footer */}
      <Footer />
    </main>
  );
}
