import React from 'react';
import { Link } from 'react-router-dom';
import { FiChevronsLeft } from 'react-icons/fi';
import sectiondata from '../../store/store';

export default function ConfirmContent() {
  return (
    <section className='error-content-wrapper text-center'>
      <div className='error-content-width'>
        {sectiondata.confirmpagedata.img ? (
          <img className='' src={sectiondata.confirmpagedata.img} alt='Error' />
        ) : (
          ''
        )}
        {sectiondata.confirmpagedata.title ? <h1>{sectiondata.confirmpagedata.title}</h1> : ''}
        {sectiondata.confirmpagedata.content ? <p>{sectiondata.confirmpagedata.content}</p> : ''}
        {sectiondata.confirmpagedata.connector ? (
          <div className='connector'>
            <span>{sectiondata.confirmpagedata.connector}</span>
          </div>
        ) : (
          ''
        )}
        {sectiondata.confirmpagedata.backbtn ? (
          <Link className='theme-button' to='/login'>
            <FiChevronsLeft className='icon' /> {sectiondata.confirmpagedata.backbtn}
          </Link>
        ) : (
          ''
        )}
      </div>
    </section>
  );
}
