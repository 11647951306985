import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FiTarget, FiChevronRight } from 'react-icons/fi';
import { FaHandshake } from 'react-icons/fa';
import { IoIosNotificationsOutline } from 'react-icons/io';

export default class Service3clmns extends Component {
  servicestates = {
    items: [
      {
        icon: <FiTarget className='service-items-icon' />,
        title: 'Misyonumuz.',
        description:
          'Partnerlerimizin hepsinin eticaret serüveninde işletmelerinin yürümesini ve hayatlarını kolaylaştırmak'
      },
      {
        icon: <IoIosNotificationsOutline className='service-items-icon' />,
        title: 'Vizyonumuz.',
        description:
          'Dünyanın değişmesiyle e ticaretin hayatımzındaki yerinin komple değişmesini göz önüne alarak her geçen gün kendimizi ve partnertlerimizin gelişmesini benimsiyoruz '
      },
      {
        icon: <FaHandshake className='service-items-icon' />,
        title: 'Yaklaşımımız.',
        description:
          'Her partnerimizle ilk kez tanışıyor gibi, her mağazayı ilk kez açıyor gibi , her çözümü ilk kez yapıyor gibi'
      }
    ]
  };
  render() {
    return (
      <section className='services-area service-3columns'>
        <div className='container'>
          <div className='services-items-grid'>
            {this.servicestates.items.map((item, index) => {
              return (
                <div className='service-items' key={index}>
                  <span className='service-items-num'>{index}</span>
                  <span className='service-items-iconbox'>{item.icon}</span>
                  <Link to='/service-details'>
                    <h3 className='service-items-title'>{item.title}</h3>
                  </Link>
                  <p className='service-items-description'>{item.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}
