import React from 'react';
import OrderListItem from './OrderListItem';

export default function OrderList({ value }) {
  return (
    <React.Fragment>
      {value?.map(item => {
        return <OrderListItem key={item._id} item={item} value={value} />;
      })}
    </React.Fragment>
  );
}
