import React from 'react';

export default function CheckOutItem({ item }) {
  const { title, price, count } = item;
  return (
    <>
      <tr>
        <td>
          {title} {count ? <strong>x {count}</strong> : ''}
        </td>
        <td>₺{price}</td>
      </tr>
    </>
  );
}
