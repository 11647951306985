import fetch from '../config/FetchInterceptor';

const productService = {};

productService.get = function (params) {
  return fetch({
    url: '/api/products/',
    method: 'get'
  });
};
productService.find = function (params) {
  return fetch({
    url: `/api/products/find/${params}`,
    method: 'get',
    headers: {
      'public-request': 'true'
    }
  });
};
productService.homeGet = function (params) {
  return fetch({
    url: '/api/products/home',
    method: 'get',
    headers: {
      'public-request': 'true'
    }
  });
};
export default productService;
