import fetch from '../config/FetchInterceptor';

const orderService = {};

orderService.get = function (params) {
  return fetch({
    url: `/api/orders/find/${params}`,
    method: 'get'
  });
};
orderService.getHome = function () {
  return fetch({
    url: '/api/orders/homeInfo',
    method: 'get'
  });
};
orderService.create = function (params) {
  return fetch({
    url: '/api/orders/',
    method: 'post',
    data: params
  });
};

export default orderService;
