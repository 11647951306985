import React from 'react';
import { FaGoogle, FaFacebookF, FaTwitter } from 'react-icons/fa';
import { FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import authService from '../../fetchServices/AuthService';
import { message } from 'antd';
import userService from '../../fetchServices/UserServics';

export default function ProfileForm() {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('İsim ve Soyisim zorunludur'),
    phone: Yup.string()
      .matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, 'Geçersiz Numara')
      .min(10, 'Telefon Numarası 10 Haneli olmalıdır')
      .max(10, 'Telefon Numarası 10 Haneli olmalıdır')
      .required('Telefon Numarası gereklidir')
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  const authUser = localStorage.getItem('USER');
  const _authUser = JSON.parse(authUser);

  function onSubmit(data) {
    const user = {
      name: data.name,
      phone: data.phone,
      contact: data.contact
    };
    const params = {
      user: user,
      id: _authUser?._id
    };
    userService
      .update(params)
      .then(response => {
        localStorage.setItem('USER', JSON.stringify(response));
        message.success('Başarıyla Kaydedildi! ');
      })
      .catch(err => {
        message.error('Bir hata oluştu !');
      });
    return false;
  }

  return (
    <section className='signup-form-wrapper'>
      <div className='container'>
        <div className='signup-form-box'>
          <form className='signup-form' onSubmit={handleSubmit(onSubmit)}>
            <input
              placeholder='Adınız'
              name='name'
              type='text'
              {...register('name')}
              defaultValue={_authUser.name}
              className={`form-control ${errors.name ? 'is-invalid' : ''}`}
            />
            <div className='invalid-feedback'>{errors.name?.message}</div>

            <input
              placeholder='Mail Adresiniz'
              name='email'
              disabled
              defaultValue={_authUser.email}
              type='text'
              {...register('email')}
              className={`form-control ${errors.email ? 'is-invalid' : ''}`}
            />
            <div className='invalid-feedback'>{errors.email?.message}</div>
            <input
              name='phone'
              type='number'
              placeholder='Telefon Numaranızı başında 0 olmadan giriniz'
              {...register('phone')}
              defaultValue={_authUser.phone}
              className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
            />
            <div className='invalid-feedback'>{errors.phone?.message}</div>
            <div className='checkboxes'>
              <label className='privacy-policy'>
                Erya Groups tarafından ürün, hizmet ve kampanyalarla ilgili reklam, pazarlama ve
                bilgi amaçlı her türlü ticari elektronik ileti gönderilmesine ve diğer iletişim
                araçları kullanılarak tarafımla iletişime geçilmesine izin veriyorum
                <input
                  name='contact'
                  type='checkbox'
                  {...register('contact')}
                  defaultChecked={_authUser?.contact}
                  id='contact'
                  className={`form-check-input ${errors.sms ? 'is-invalid' : ''}`}
                />
                <span className='checkmark'></span>
              </label>
            </div>
            <button type='submit' value='Register Account' className='theme-button'>
              Kaydet <FiChevronRight className='icon' />
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}
