import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { TiStarFullOutline, TiStarHalfOutline } from 'react-icons/ti';
import { IoIosQuote } from 'react-icons/io';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/owl.theme.default.min.css';
import Logo from '../../assets/images/logo.png';
const responsive = {
  0: {
    items: 1
  },
  450: {
    items: 1
  },
  600: {
    items: 1
  },
  1000: {
    items: 2
  }
};
export default function Testimonial({ referenceImg }) {
  return (
    <>
      <div className='container'>
        <OwlCarousel
          className='owl-theme'
          loop={true}
          autoplay={true}
          margin={10}
          dots={true}
          nav={false}
          responsiveClass={true}
          responsive={responsive}
        >
          {referenceImg?.map((item, index) => {
            const { image, _id } = item;
            return (
              <div className='testimonial-items' key={index}>
                <div className='testimonial-bottom-flex'>
                  <img
                    height='5%'
                    width='5%'
                    src={'https://api.eryagroups.com/' + image}
                    alt={item?._id.substring(0, 10)}
                  />
                </div>
              </div>
            );
          })}
        </OwlCarousel>
      </div>
    </>
  );
}
