import React from 'react';
import HeaderConfirm from '../components/other/ConfirmHeader';
import FooterError from '../components/other/FooterError';
import ConfirmContent from '../components/other/ConfirmContent';
import authService from '../fetchServices/AuthService';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
export default function Confirm(props) {
  let history = useHistory();
    
  if (props.match.path === '/confirm/:confirmationCode') {
    authService
      .check(props.match.params.confirmationCode)
      .then(response => {
        message.success('Hesabınız Onaylandı!');
      })
      .catch(err => {
        message.error('Hata Oluştu');
      });
  }
  return (
    <div className='error-wrap'>
      <span className='error-circle'></span>
      <span className='error-circle'></span>
      <span className='error-circle'></span>
      <span className='error-circle'></span>
      <span className='error-circle'></span>
      <span className='error-circle'></span>
      <span className='error-circle'></span>
      <span className='error-circle'></span>
      <HeaderConfirm />
      <div className='container'>
        <ConfirmContent />
      </div>
    </div>
  );
}
