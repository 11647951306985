import React from 'react';
import { FaGoogle, FaFacebookF, FaTwitter } from 'react-icons/fa';
import { FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { message } from 'antd';
import userService from '../../fetchServices/UserServics';
import { useHistory } from 'react-router-dom';
export default function PasswordForm() {
  let history = useHistory();
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'Şifreniz en az 6 karakterli olmalıdır')
      .required('Şifre gereklidir'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Şifreler Uyumlu Değil')
      .required('Şifre gereklidir')
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  const authUser = localStorage.getItem('USER');
  const _authUser = JSON.parse(authUser);

  function onSubmit(data) {
    const user = {
      password: data.password
    };
    const params = {
      user: user,
      id: _authUser?._id
    };
    userService
      .update(params)
      .then(response => {
        message.success('Sifreniz başarıyla değiştirildi ');
        localStorage.removeItem('AUTH_TOKEN');
        localStorage.removeItem('USER');
        history.push('/');
      })
      .catch(err => {
        message.error('Bir hata oluştu !');
      });
    return false;
  }

  return (
    <section className='signup-form-wrapper'>
      <div className='container'>
        <div className='signup-form-box'>
          <form className='signup-form' onSubmit={handleSubmit(onSubmit)}>
            <input
              name='password'
              type='password'
              placeholder='Şifre'
              {...register('password')}
              className={`form-control ${errors.password ? 'is-invalid' : ''}`}
            />
            <div className='invalid-feedback'>{errors.password?.message}</div>
            <input
              name='confirmPassword'
              type='password'
              placeholder='Şifre Tekrarı'
              {...register('confirmPassword')}
              className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
            />
            <div className='invalid-feedback'>{errors.confirmPassword?.message}</div>
            <button type='submit' value='Register Account' className='theme-button'>
              Kaydet <FiChevronRight className='icon' />
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}
