import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './contentment.css';
import { Player } from 'video-react';
import PosterImg from '../../assets/images/videoPoster1.png';
import VideoPlayer from 'react-video-js-player';

const ImageGrid = ({ setSelectedImg, Img }) => {
  const [player, setPlayer] = useState();
  const onPlayerReady = player => {
    setPlayer(player);
  };
  return (
    <div className='img-grid'>
      {Img &&
        Img?.map(doc => (
          <motion.div
            className='img-wrap'
            key={doc.id}
            layout
            whileHover={{ opacity: 1 }}
            s
            onClick={() => setSelectedImg(doc.image)}
          >
            {doc?.image && (
              <motion.img
                src={'https://api.eryagroups.com/' + doc.image}
                alt='uploaded pic'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1 }}
              />
            )}
            {doc?.video && (
              <motion.div className='video'>
                <VideoPlayer
                  onReady={onPlayerReady.bind(this)}
                  width="300"
                  height="533"
                  controls={true}
                  src={'https://api.eryagroups.com/' + doc?.video}
                  poster={PosterImg}
                />
              </motion.div>
            )}
          </motion.div>
        ))}
      <link rel='stylesheet' href='https://video-react.github.io/assets/video-react.css' />
    </div>
  );
};

export default ImageGrid;
