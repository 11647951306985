import React from 'react';
import { Switch, Route, HashRouter, BrowserRouter, Redirect } from 'react-router-dom';

function PrivateRoute({ component: Component, ...rest }) {
  const authUser = localStorage.getItem('USER');
  const _authUser = JSON.parse(authUser);
  return (
    <Route
      {...rest}
      render={props =>
        _authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
