import fetch from '../config/FetchInterceptor';

const userService = {};

userService.get = function (params) {
  return fetch({
    url: '/api/users/',
    method: 'get'
  });
};
userService.login = function (params) {
  return fetch({
    url: '/api/auth/login',
    method: 'post',
    data: params,
    headers: {
      'public-request': 'true'
    }
  });
};
userService.update = function (params) {
  return fetch({
    url: `/api/users/${params.id}`,
    method: 'put',
    data: params.user
  });
};
export default userService;
