import React from 'react';
import { FiChevronRight } from 'react-icons/fi';

export default function SubscribeForm() {
  return (
    <div className='contact-form-subscribe-content'>
      <form>
        <div className='d-flex'>
          <input type='email' name='email' placeholder='Mail adresinizi giriniz!' />
          <button type='submit' className='theme-button'>
            Kayıt <FiChevronRight className='icon' />
          </button>
        </div>
      </form>
    </div>
  );
}
