import React, { Component } from 'react';
import LoaderImage from '../../assets/images/loader.png';

const FullPageLoader = props => {
  const { loading } = props;

  if (!loading) return null;

  return (
    <div class='loader-container'>
      <div className='loader'>
        <img className='loaderImage' src={LoaderImage} />
        <h1 className='loaderImageText'>YÜKLENİYOR</h1>
      </div>
    </div>
  );
};
export default FullPageLoader;
