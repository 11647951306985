import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
// import $ from 'jquery'

export default function OrderListItem({ item, value }) {
  const { _id, desc, title, price, quantity, img, products, documentID } = item;
  const { product } = products;
  const getPaymentStatus = status => {
    if (status === 'success') {
      return <a>Ödeme Başarılı</a>;
    }
    if (status === 'pending') {
      return <a>Ödeme Bekliyor</a>;
    }
    if (status === 'cancel') {
      return <a>Ödeme Başarısız</a>;
    }
    return '';
  };
  return (
    <tr>
      <td className='product-image'>
        <Link to='/product-details' onClick={() => value.handleDetails(_id)}>
          <img src={'https://api.eryagroups.com/' + products[0].product[0].img} alt='Cart' />
        </Link>
      </td>
      <td className='product-description'>
        {products[0].product[0].title}
        <p>{products[0].product[0].desc.substring(0, 80) + '...'}</p>
      </td>
      <td className='product-price text-center'>₺{products[0].product[0].price}</td>
      <td className='product-quantity text-center'>
        <div className='product-details-quantity'>
          <span type='number' className='btn btn-black mx-1'>
            {products[0].product[0].quantity}
          </span>
        </div>
      </td>
      <td className='product-total'>{moment(item?.createdAt).format('DD-MM-YYYY HH:mm')}</td>
      <td className='product-quantity text-center'> {getPaymentStatus(item?.status)}</td>
      <td className='product-quantity text-center'>
        {item?.status === 'success' ? 'Teslim Edildi ' : 'Ödeme Bekliyor'}
      </td>
    </tr>
  );
}
