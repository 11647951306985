import fetch from '../config/FetchInterceptor';

const referenceService = {};

referenceService.get = function (params) {
  return fetch({
    url: '/api/reference/',
    method: 'get',
    headers: {
      'public-request': 'true'
    }
  });
};
export default referenceService;
