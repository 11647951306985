import React, { useEffect } from 'react';
import Navbar from '../components/common/Navbar';
import Footer from '../components/other/Footer';
import SectionsTitle from '../components/common/SectionsTitle';

const PrivacyPolicy = () => {
  return (
    <main className='about-page'>
      <header className='header-area'>
        <Navbar />
      </header>

      <div style={{ marginTop: '10%' }} className='about-wrapper'>
        <div className='container'>
          <div className='about-grid-wrap'>
            <div className='about-left'>
              <SectionsTitle title='Gizlilik Sözleşmesi' />
              <p className='about-left-desc'>
                Bu gizlilik politikası ile ER-YA Group internet sitesi Eryagroups.com üzerinden
                kullanıcıların Eryagroups.com da bulunan kişisel bilgilerinin kullanımı ve korunması
                düzenlenmektedir.  Kullanıcıların kişisel bilgileri, Adı – Soyadı, Doğum tarihi,
                Telefon numarası, e-posta adresi ve T.C kimlik numarası gibi kullanıcıyı doğrudan ya
                da dolaylı olarak tanımlamaya yönelik her türlü kişisel bilgi olup, bu gizlilik
                politikasında “kişisel bilgiler” olarak anılacaktır.  Eryagroups.com
                kursiyerlerinin, kişisel bilgileri, bu gizlilik politikası ile belirlenen esas ve
                kapsam haricinde hiçbir şekilde kullanılmayacak ve ancak kullanıcının açık rızası
                ile üçüncü kişilerle paylaşılacaktır. Eryagroups.com kişisel bilgilerin ve
                Eryagroups.com kullanıcılarının gerçekleştirmiş olduğu işlem bilgilerinin
                güvenliğini ve gizliliğini sağlamak için gerekli önlemleri almaktadır.
                 Eryagroups.com bu amaçla, başka programlar kullanabilir. Ancak,  Eryagroups.com
                tarafından gerekli bilgi güvenliği önlemlerinin almasına karşın internet sitesine
                ve/veya sisteme yapılan saldırılar sonucunda kişisel bilgilerin zarar görmesi veya
                üçüncü kişilerin eline geçmesi durumunda, Eryagroups.com bu nedenle herhangi bir
                sorumluluğu olmayacaktır. Bir kullanıcının, bir başka kullanıcının bilgilerine
                ulaşması ve bu bilgileri değiştirmesi mümkün değildir. Kullanıcının kişisel
                bilgileri, Eryagroups.com haricinde yalnızca kullanıcı tarafından ulaşılabilir ve bu
                bilgiler ancak kullanıcı tarafından güncellenebilir. Kullanıcının talebi ile kişisel
                bilgiler Eryagroups.com tarafından da güncellenebilecektir. İdari kurumlar ve adli
                kurumlar tarafından gelen talepler ve bildirimler doğrultusunda kişisel bilgiler,
                Gizlilik Politikasına uygun olarak adli ve idari kurumlar ile paylaşılabilir.
                Kullanıcının kişisel bilgileri; gerçekleştirilen işlemlerde gerekli görülmesi
                halinde, sürecin hızlandırılması ve kullanıcılar arasındaki iletişimi sağlamak
                amacıyla işleme dahil olan diğer kullanıcı ile paylaşılabilir.  Eryagroups.com 
                internet sitesini ziyaret eden her kullanıcı, Eryagroups.com gizlilik politikası
                hükümlerini kabul etmiş sayılacaktır
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default PrivacyPolicy;
