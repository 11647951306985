import React from 'react';
import SectionsTitle from '../common/SectionsTitle';
import { FiChevronRight } from 'react-icons/fi';
import ContactSidebar from './ContactSidebar';
import Maps from '../contact/Maps';
import CtaNewsletter from '../../sections/CtaNewsletter';
import sectiondata from '../../store/store';
import SocialProfile from '../widgets/SocialProfile';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import contactService from '../../fetchServices/ContactService';
import { message } from 'antd';

export default function ContactDetails() {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('İsim ve Soyisim zorunludur'),
    subject: Yup.string().required('Konu Zorunludur.'),
    email: Yup.string().required('Email Zorunludur.').email('Email Zorunludur.'),
    phone: Yup.string()
      .matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, 'Geçersiz Numara')
      .min(10, 'Telefon Numarası 10 Haneli olmalıdır')
      .max(10, 'Telefon Numarası 10 Haneli olmalıdır')
      .required('Telefon Numarası gereklidir')
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  function onSubmit(data) {
    const params = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      subject: data.subject
    };
    contactService
      .create(params)
      .then(response => {
        message.success(
          'Bilgileriniz Bize Ulaştı ! En Yakın Zaman da Uzman Bir Ekip Arkadaşımız Sizi Arayacak.'
        );
      })
      .catch(err => {
        message.error('Beklenmeyen Bir Hata Oluştu');
      });
    return false;
  }
  return (
    <section className='contact-form-wrapper'>
      <div className='container'>
        <div className='contact-form-grid d-grid'>
          <div className='left'>
            <SectionsTitle
              title={sectiondata.contactdata.secTitle}
              subtitle={sectiondata.contactdata.secSubtitle}
            />
            <p>{sectiondata.contactdata.content}</p>
            <div className='sidebar-social-profile'>
              <SocialProfile lists={sectiondata.contactdata.socials} />
            </div>
          </div>
          <div className='right'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='form-grid d-grid'>
                <div className='left'>
                  <label htmlFor='name'>
                    Adınız <sup>*</sup>
                  </label>
                  <input
                    placeholder='Adınız Soyadınız'
                    name='name'
                    type='text'
                    {...register('name')}
                    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                  />
                  <div className='invalid-feedback'>{errors.name?.message}</div>
                </div>
                <div className='right'>
                  <label htmlFor='email'>
                    Mail Adresiniz <sup>*</sup>
                  </label>
                  <input
                    placeholder='Mail Adresiniz'
                    name='email'
                    type='text'
                    {...register('email')}
                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  />
                  <div className='invalid-feedback'>{errors.email?.message}</div>
                </div>
                <div className='left'>
                  <label htmlFor='number'>
                    Numaranız <sup>*</sup>
                  </label>
                  <input
                    name='phone'
                    type='number'
                    placeholder='Telefon Numaranızı başında 0 olmadan giriniz'
                    {...register('phone')}
                    className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                  />
                  <div className='invalid-feedback'>{errors.phone?.message}</div>
                </div>
                <div className='right'>
                  <label htmlFor='subject'>
                    Konu <sup>*</sup>
                  </label>
                  <input
                    placeholder='Konu'
                    name='subject'
                    type='text'
                    {...register('subject')}
                    className={`form-control ${errors.subject ? 'is-invalid' : ''}`}
                  />
                  <div className='invalid-feedback'>{errors.subject?.message}</div>
                </div>
              </div>
              <p>
                * Formu göndermeniz halinde tarafınıza yazılı ya da sözlü iletişime geçilmesi için
                onay vermiş sayılırsınız.
              </p>
              <p />
              <button type='submit' value='Register Account' className='theme-button'>
                Beni Arayın
              </button>
            </form>
          </div>
        </div>

        <CtaNewsletter
          title={sectiondata.ctadata.cta1.title}
          content={sectiondata.ctadata.cta1.content}
        />
      </div>
    </section>
  );
}
