import React, { Component } from 'react';
import { ProductConsumer } from '../../../productcontext';
import { selectCartItems, selectCartTotal } from '../../../redux/Cart/cart.selectors';
import { createStructuredSelector } from 'reselect';
import { useSelector } from 'react-redux';

import MenuCartList from './MenuCartList';
import MenuCartTotals from './MenuCartTotals';
const mapState = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal
});

const MenuCart = () => {
  const { cartItems, total } = useSelector(mapState);
  return (
    <>
      {cartItems?.length > 0 ? (
        cartItems?.length > 3 ? (
          <div className='cart__items moreproducts'>
            <MenuCartList value={cartItems} />
            <MenuCartTotals value={cartItems} />
          </div>
        ) : (
          <div className='cart__items'>
            <MenuCartList value={cartItems} />
            <MenuCartTotals value={cartItems} />
          </div>
        )
      ) : (
        <div className='cart__items'>
          <h4 className=' text-center'>Sepetiniz Boş!</h4>
        </div>
      )}
    </>
  );
};
export default MenuCart;
