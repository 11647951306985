import React from 'react';
import HeaderConfirm from '../components/other/NoPaymentError';
import FooterError from '../components/other/FooterError';
import NoPaymentContent from '../components/other/NoPaymentContant';
import authService from '../fetchServices/AuthService';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
export default function NoPayment(props) {
  return (
    <div className='error-wrap'>
      <span className='error-circle'></span>
      <span className='error-circle'></span>
      <span className='error-circle'></span>
      <span className='error-circle'></span>
      <span className='error-circle'></span>
      <span className='error-circle'></span>
      <span className='error-circle'></span>
      <span className='error-circle'></span>
      <HeaderConfirm />
      <div className='container'>
        <NoPaymentContent />
      </div>
    </div>
  );
}
