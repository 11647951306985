import fetch from '../config/FetchInterceptor';

const authService = {};

authService.login = function (params) {
  return fetch({
    url: '/api/auth/login',
    method: 'post',
    data: params,
    headers: {
      'public-request': 'true'
    }
  });
};

authService.crete = function (params) {
  return fetch({
    url: '/api/auth/register',
    method: 'post',
    data: params,
    headers: {
      'public-request': 'true'
    }
  });
};

authService.check = function (params) {
  return fetch({
    url: `/api/auth/confirm/${params}`,
    method: 'post',
    headers: {
      'public-request': 'true'
    }
  });
};
export default authService;
