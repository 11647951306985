import React from 'react';
import CartHead from './CartHead';
import CartLists from './CartLists';
import { FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import CartTotals from './CartTotals';
import { selectCartItems, selectCartTotal } from '../../../redux/Cart/cart.selectors';
import { createStructuredSelector } from 'reselect';
import { useSelector } from 'react-redux';

const mapState = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal
});
const CartDetails = () => {
  const { cartItems, total } = useSelector(mapState);
  return (
    <section className='product-cart-wrapper'>
      <div className='container'>
        <>
          {cartItems?.length > 0 ? (
            <React.Fragment>
              <div className='cart-table'>
                <table>
                  <CartHead />
                  <tbody>
                    <CartLists value={cartItems} />
                  </tbody>
                </table>
              </div>
              <div className='product-cart-table-foot d-flex'>
                <div className='table-foot left'>
                  {/*<form className='d-flex'>
                    <input type='text' placeholder='Coupon Code' />
                    <button type='submit' className='theme-button'>
                      Apply Coupon <FiChevronRight className='icon' />
                    </button>
          </form>*/}
                </div>
                <div className='table-foot right'>
                  <Link to='/' className='theme-button'>
                    Alışverişe Devam Et <FiChevronRight className='icon' />
                  </Link>
                </div>
              </div>
              <div className='product-cart-totals'>
                <CartTotals value={cartItems} />
              </div>
            </React.Fragment>
          ) : (
            <div className='empty-cart-items text-center'>
              <h1>Sepetiniz Boş</h1>
              <Link to='/' className='theme-button'>
                Ürünleri Gör <FiChevronRight className='icon' />
              </Link>
            </div>
          )}
        </>
      </div>
    </section>
  );
};
export default CartDetails;
// how to save data on redux in react
