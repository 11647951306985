import React from 'react';

export default function OrderCartHead() {
  return (
    <thead>
      <tr>
        <td className='product-image'>Ürün Görseli</td>
        <td className='product-desc'>Ürün Bilgileri</td>
        <td className='product-price text-center'>Fiyat</td>
        <td className='product-quantity text-center'>Adet</td>
        <td className='product-total text-center'>Sipariş Tarihi</td>
        <td className='product-remove text-center'>Ödeme Durumu</td>
        <td className='product-remove text-center'>Sipariş Durumu</td>
      </tr>
    </thead>
  );
}
