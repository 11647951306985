import React from 'react';
import { ProductConsumer } from '../../productcontext';
import BestProductContent from './BestProductContent';

export default function BestProducts(props) {
  const { product } = props;
  return (
    <section className='best-products-wrapper'>
      <div className='container'>
        <div className='products best-products-grid d-grid'>
          {product?.map(item => {
            return <BestProductContent key={item._id} product={item} />;
          })}
        </div>
      </div>
    </section>
  );
}
