import React from 'react';
import { Link } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';

import { useSelector } from 'react-redux';
import { selectCartTotal } from '../../../redux/Cart/cart.selectors';
import { createStructuredSelector } from 'reselect';
const mapState = createStructuredSelector({
  total: selectCartTotal
});

const auth = localStorage.getItem('AUTH_TOKEN');

export default function CartTotals() {
  const { total } = useSelector(mapState);
  const tax = total * 0.18;
  const subTotal = total - tax;
  return (
    <React.Fragment>
      <div className='container'>
        <div className='product-cart-totals-box'>
          <h1>Cart Totals</h1>
          <h2 className='d-flex subtotal'>
            Ara Toplam: <strong>₺ {subTotal}</strong>
          </h2>
          <h2 className='d-flex tax'>
            Vergi (%18): <strong>₺ {tax}</strong>
          </h2>
          <h2 className='d-flex total'>
            Toplam: <strong>₺ {total}</strong>
          </h2>
          {auth ? (
            <Link to='/checkout' className='theme-button'>
              Ödeme <FiChevronRight className='icon' />
            </Link>
          ) : (
            <Link to='/login' className='theme-button'>
              Giriş Yap <FiChevronRight className='icon' />
            </Link>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
