import React, { useState } from 'react';
import { FiChevronRight, FiPhone } from 'react-icons/fi';
import { ProductConsumer } from '../../../productcontext';
import CheckOutList from './CheckOutList';
import CheckOutTotal from './CheckOutTotal';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { FaRegEnvelope } from 'react-icons/fa';
import CustomerItems from './order/CustomerItemList';
import OrderTotal from './order/OrderTotal';
import { selectCartItems, selectCartTotal } from '../../../redux/Cart/cart.selectors';
import { createStructuredSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import orderService from '../../../fetchServices/OrderService';
import { message } from 'antd';
import PaymentScreen from './PaymentScreen';
import FullPageLoader from '../../other/Loader';
const mapState = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal
});
const CheckOut = () => {
  const authUser = localStorage.getItem('USER');
  const _authUser = JSON.parse(authUser);
  const tempDate = new Date();
  const [paymentToken, setPaymentToken] = useState();
  const [loading, setLoading] = useState();
  const date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate();
  const currDate = date;
  const { cartItems, total } = useSelector(mapState);
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    pnumber: '',
    company: '',
    address: '',
    address2: '',
    country: '',
    town: '',
    state: '',
    postcode: ''
  });
  const handleSubmit = e => {
    setLoading(true);
    e.preventDefault();
    // Note: open your browser console then you wi
    $('.product-ordered').removeClass('d-none').addClass('active');
    $('.checkout-area form').addClass('d-none');
    const params = {
      products: { product: cartItems },
      userName: formData.fname,
      phoneOrder: formData.pnumber,
      amount: total,
      email: formData.email,
      address:
        formData.addressl1 +
        formData.addressl2 +
        formData.country +
        formData.town +
        formData.state +
        formData.postcode,
      user: _authUser,
      userId: _authUser?._id
    };
    orderService
      .create(params)
      .then(response => {
        window.location.href = `https://www.paytr.com/odeme/guvenli/${response}`;
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        message.error('Bir Hata Oluştu');
      });
  };
  if (loading) return <FullPageLoader loading={loading} />;
  return (
    <>
      {cartItems?.length > 0 ? (
        <form onSubmit={handleSubmit}>
          <div className='container'>
            <div className='row'>
              <div className='col-7'>
                <div className='billing-content checkout-box'>
                  <h1></h1>
                  <div className='d-grid'>
                    <div className='form-field'>
                      <label htmlFor='fname'>
                        İsim Soyisim <sup>*</sup>
                      </label>
                      <input
                        value={formData.fname}
                        type='text'
                        name='fname'
                        id='fname'
                        placeholder='İsim Soyisim'
                        required
                        onChange={e =>
                          setFormData({ ...formData, [e.target.name]: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className='d-grid'>
                    <div className='form-field'>
                      <label htmlFor='email'>
                        Email <sup>*</sup>
                      </label>
                      <input
                        value={formData.email}
                        type='email'
                        name='email'
                        id='email'
                        placeholder='Type your email'
                        required
                        onChange={e =>
                          setFormData({ ...formData, [e.target.name]: e.target.value })
                        }
                      />
                    </div>
                    <div className='form-field'>
                      <label htmlFor='pnumber'>
                        Telefon Numarası <sup>*</sup>
                      </label>
                      <input
                        value={formData.pnumber}
                        type='tel'
                        name='pnumber'
                        id='pnumber'
                        placeholder='Telefon Numarası'
                        required
                        onChange={e =>
                          setFormData({ ...formData, [e.target.name]: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className='form-field'>
                    <label htmlFor='addressl1'>
                      Adres <sup>*</sup>
                    </label>
                    <input
                      value={formData.address1}
                      type='text'
                      name='addressl1'
                      id='addressl1'
                      placeholder='Adres Satırı 1'
                      required
                      onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                    />
                    <input
                      value={formData.address2}
                      type='text'
                      name='addressl2'
                      id='addressl2'
                      placeholder='Adres Satırı 2'
                      onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                    />
                  </div>

                  <div className='d-grid'>
                    <div className='form-field'>
                      <label htmlFor='town'>
                        Şehir <sup>*</sup>
                      </label>
                      <input
                        value={formData.town}
                        type='text'
                        name='town'
                        id='town'
                        placeholder='Şehir'
                        required
                        onChange={e =>
                          setFormData({ ...formData, [e.target.name]: e.target.value })
                        }
                      />
                    </div>
                    <div className='form-field'>
                      <label htmlFor='state'>
                        İlçe <sup>*</sup>
                      </label>
                      <input
                        value={formData.state}
                        type='text'
                        name='state'
                        id='state'
                        placeholder='İlçe'
                        required
                        onChange={e =>
                          setFormData({ ...formData, [e.target.name]: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className='form-field'>
                    <label htmlFor='postcode'>
                      Posta Kodu <sup>*</sup>
                    </label>
                    <input
                      value={formData.postcode}
                      type='text'
                      name='postcode'
                      id='postcode'
                      placeholder='Posta Kodu'
                      required
                      onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-5'>
                <div className='order-summary checkout-box'>
                  <h1>Sipariş Özeti</h1>
                  <div className='products-total-summary'>
                    <table>
                      <thead>
                        <tr>
                          <th>Ürün</th>
                          <th>Fiyat</th>
                        </tr>
                      </thead>
                      <tbody className='items'>
                        <CheckOutList value={cartItems} />
                      </tbody>
                      <CheckOutTotal value={cartItems} />
                    </table>
                  </div>
                  <div className='order-summary-payment-method'>
                    <button type='submit' className='theme-button'>
                      Sipariş Ver <FiChevronRight className='icon' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className='empty-cart-items text-center'>
          <h1>Your cart is empty!</h1>
          <Link to='/shop' className='theme-button'>
            Return to Shop <FiChevronRight className='icon' />
          </Link>
        </div>
      )}
    </>
  );
};

export default CheckOut;
