import React, { useEffect } from 'react';
import Navbar from '../components/common/Navbar';
import Footer from '../components/other/Footer';

import SectionsTitle from '../components/common/SectionsTitle';

const Kvkk = () => {
  return (
    <main className='about-page'>
      <header className='header-area'>
        <Navbar />
      </header>

      <div style={{ marginTop: '10%' }} className='about-wrapper'>
        <div className='container'>
          <div className='about-grid-wrap'>
            <div className='about-left'>
              <SectionsTitle title='Kişisel Verilerin Korunması Kanunu Aydınlatma' />
              <p>
                <li>
                  <strong>
                    ERYA GROUP EĞİTİM & DANIŞMANLIK ("ERYA") olarak kişisel verileriniz konusunda
                    büyük bir hassasiyet göstermekteyiz. Bu nedenle, temel hak ve özgürlüklerinizi
                    korumak amacıyla yürürlükte olan 6698 Sayılı Kişisel Verilerin Korunması
                    Kanunu'nu ("KVKK") kapsamındaki kişisel verilerinizin kullanımı ve korunması ile
                    ilgili haklarınız hakkında sizi bilgilendirmek istiyoruz.{' '}
                  </strong>
                </li>
              </p>

              <p>
                <strong> 1. Kişisel Veri Nedir?</strong>
              </p>
              <p>
                Kişisel veri, KVKK 'da kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her
                türlü bilgi olarak tanımlanmıştır. Buna göre bizimle paylaştığınız adınız,
                soyadınız, elektronik posta adresiniz ve telefon numaranız kişisel veri olarak
                adlandırılmaktadır.
              </p>
              <p>
                <strong> 2. Kişisel verilerinizin tarafımızca toplanmasının amacı nedir?</strong>
              </p>
              <p>
                ERYA ile olan ürün ve hizmet ilişkiniz dolayısıyla yasal yükümlülüklerimizi yerine
                getirebilmek ve sizi Şirketimiz bünyesindeki kampanya, yenilik ve gelişmelerden
                haberdar edebilmek için kişisel bilgilerinizi toplamakta ve bu kapsamda
                işlemekteyiz.
              </p>
              <p>
                <strong> 3. Kişisel verilerinizi hangi amaçla, kimlere aktarıyoruz? </strong>
              </p>
              <p>
                Kişisel verileriniz ERYA ile doğrudan/dolaylı yurtiçi/yurtdışı iştiraklerimizle, iş
                ilişkisinin devamı esnasında birlikte bizi temsil eden ve/veya faaliyetlerimizi
                yürütebilmek için iş birliği yaptığımız iş ortağımız olan kurum, kuruluşlarla
                paylaşılabilmektedir. Ayrıca, yasal yükümlülüklerimiz nedeniyle ve bunlarla sınırlı
                olmak üzere mahkemeler ve diğer kamu kurumları ile kişisel veriler paylaşılmaktadır.
              </p>
              <p>
                <strong> 4. Kişisel verilerinizi nasıl saklıyoruz?</strong>
              </p>
              <p>
                ERYA ile paylaşılan kişisel verileriniz ilgili yasal düzenlemelere, KVKK hükümlerine
                ve ERYA standartlarına uygun olarak saklanmaktadır.
              </p>
              <p>
                <strong> 5. Kişisel verilerinizi ne kadar süre ile tutuyoruz? </strong>
              </p>
              <p>
                ERYA olarak kişisel verilerinizi KVKK 'ya uygun olarak saklamaktayız. KVKK Md.
                7/f.1.'e göre işlenmesi gerektiren amaç ortadan kalktığında ve/veya mevzuat uyarınca
                verilerinizi işlememiz için zorunlu kılındığımız zamanaşımı süreleri dolduğunda,
                kişisel verileriniz tarafımızca silinecek, yok edilecek veya anonimleştirerek
                kullanılmaya devam edilecektir.
              </p>
              <p>
                <strong>
                  6. Kişisel Verilerin Korunması Kanunu'ndan doğan haklarınız nelerdir?{' '}
                </strong>
              </p>
              <p>
                İşlenen kişisel verilerinizle ilgili mevzuat uyarınca; kişisel veri işlenip
                işlenmediğini öğrenme, kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
                kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp
                kullanılmadığını öğrenme, yurt içinde veya yurt dışında kişisel verilerin
                aktarıldığı üçüncü kişileri bilme, kişisel verilerin eksik veya yanlış işlenmiş
                olması hâlinde bunların düzeltilmesini isteme, kişisel verilerin silinmesini veya
                yok edilmesini isteme, kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
                bunların düzeltilmesine ve/veya kişisel verilerin silinmesini veya yok edilmesine
                ilişkin işlemlerin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini
                isteme, işlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi
                suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme, kişisel
                verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın
                giderilmesini talep etme haklarına sahipsiniz. kvkk@eryagroups.com üzerinden
                iletişime geçmeniz halinde iletilecek olan formu doldurarak başvurabilirsiniz.
                Başvurularınıza yasal süresi içerisinde geri dönüş sağlamaktayız.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Kvkk;
