import React, { useEffect } from 'react';
import Navbar from '../components/common/Navbar';
import Footer from '../components/other/Footer';
import SectionsTitle from '../components/common/SectionsTitle';

const SalesContract = () => {
  return (
    <main className='about-page'>
      <header className='header-area'>
        <Navbar />
      </header>

      <div style={{ marginTop: '10%' }} className='about-wrapper'>
        <div className='container'>
          <div className='about-grid-wrap'>
            <div className='about-left'>
              <SectionsTitle title='Mesafeli Satış Sözleşmesi' />
              <p>
                <li>
                  {' '}
                  <strong>MADDE 1 – SÖZLEŞMENİN KONUSU VE TARAFLAR</strong>
                </li>
              </p>
              <p>
                1.1. İşbu sözleşme, aşağıda detay bilgileri bulunan ALICI’nın,SATICI tarafından
                işletilmekte olan www.eryagroups.com; (bundan sonra WEB SİTESİ olarak anılacaktır)
                web sitesi üzerinden yapmış olduğu ürün ve hizmetlerin satışı ve ürünlerin teslimatı
                ile ilgili olarak 4077 sayılı Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli
                Sözleşmeler Uygulama Esas ve Usulleri Hakkında Yönetmelik hükümleri gereğince
                tarafların hak, hukuk ve yükümlülüklerini tespit eder.
              </p>
              <p>
                1.2. ALICI,  satışa konu mal veya hizmetlerin temel nitelikleri, satış fiyatı, ödeme
                şekli, teslimat koşulları ve satışa konu mal veya hizmetler ile ilgili tüm ön
                bilgiler ve “cayma” hakkı konusunda bilgi sahibi olduğunu, bu ön bilgileri
                elektronik ortamda teyit ettiğini ve sonrasında mal veya hizmetleri sipariş
                verdiğini işbu sözleşme hükümlerince kabul ve beyan eder. www.eryagroups.com
                sitesinde ödeme sayfasında yer alan ön bilgilendirme ve fatura iş bu sözleşmenin
                ayrılmaz parçalarıdır.
              </p>
              <p>1.3. SATICI BİLGİLERİ</p>
              <p>
                <strong>Ünvanı:</strong> ER-YA GROUP EĞİTİM & DANIŞMANLIK
              </p>
              <p>
                <strong>Adresi:</strong>Sarısu Mahallesi Atatürk Bulvarı No 150/2 Manisa / Alaşehir
              </p>
              <p>
                <strong>Telefon:</strong> 0 544 914 00 94  Eposta : info@eryagroups.com
              </p>
              <p>1.4. ALICI BİLGİLERİ</p>
              <p>
                <strong>Adı Soyadı / Ünvanı :</strong> [fatura-kullanici-adi] – [kullanici-adi]
              </p>
              <p>
                <strong>T.C Kimlik No:</strong>[tc-no]
              </p>
              <p>
                <strong>Adresi:</strong>[fatura-adresi]
              </p>
              <p>
                <strong>Telefon:</strong>[telefon-no]
              </p>
              <p>
                <strong>Eposta:</strong>[e-posta-adresi]
              </p>
              <p>
                <strong>MADDE 2 – SÖZLEŞMENİN TARİHİ</strong>
              </p>
              <p>
                2.1. İşbu sözleşme ALICI’nın WEB SİTESİ üzerinde siparişinin tamamlandığı [tarih]
                tarihinde taraflarca anlaşma gerçekleştirilmiş ve sözleşmenin bir kopyası ALICI
                eposta adresine gönderilmiştir.
              </p>
              <p>
                <strong>MADDE 3- SÖZLEŞME KONUSU ÜRÜN VEYA HİZMET</strong>
              </p>
              <p>
                3.1. Ürün veya hizmetin cinsi ve türü, erişim süresi ve iade koşulları her hizmet ve
                ürünün ilgili sayfasında belirtilmekte ve ürün veya hizmeti satın alarak bu şartları
                kabul etmiş sayılırsınız.
              </p>
              <p>
                 ALICI tarafından siparişi gerçekleştirilen ürün ve hizmetlerin detayları, vergiler
                dahil peşin satış tutarları ve adet bilgileri aşağıda belirtilmektedir. Aşağıdaki
                tabloda belirtilen ürünlerin tümü bundan sonra ÜRÜN olarak tanımlanmaktadır.
              </p>
              <p>Sipariş İçeriği:</p>
              <table
                style={{ width: '100%', marginTop: '2%', borderCollapse: 'collapse' }}
                border='2'
              >
                <tbody>
                  <tr>
                    <td style={{ width: '50%', padding: 20 }}>Tarih</td>
                    <td style={{ width: '50%', padding: 20 }}>Ürün(ler)</td>
                  </tr>
                </tbody>
              </table>
              <p>
                <strong>MADDE 4 – ÖDEME ŞEKLİ</strong>
              </p>
              <p>
                4.1. ALICI, vadeli satışların sadece Bankalara ait kredi kartları ile yapılması
                nedeniyle, alıcı, ilgili faiz oranlarını ve temerrüt faizi ile ilgili bilgileri
                bankasından ayrıca teyit edeceğini, yürürlükte bulunan mevzuat hükümleri gereğince
                faiz ve temerrüt faizi ile ilgili hükümlerin Banka ve alıcı arasındaki kredi kartı
                sözleşmesi kapsamında uygulanacağını kabul, beyan ve taahhüt eder. Bankalar ve
                finansman kuruluşları gibi kredi kartı, taksit kart v.b. veren kuruluşlarca sağlanan
                vadeli / taksitli ödeme imkanları bir kredi ve/veya doğrudan anılan kuruluşca
                sağlanmış taksitli ödeme imkanıdır; bu çerçevede gerçekleşen ve SATICI’nın bedelini
                tamamen tahsil ettiği ÜRÜN satışları işbu Sözleşme’nin tarafları yönünden taksitli
                satış sayılmaz, peşin satıştır. SATICI’nın kanunen taksitle satış sayılan hallerdeki
                yasal hakları (taksitlerden herhangi birinin ödenmemesi halinde sözleşmeyi fesih
                ve/veya kalan borcun tümünün temerrüt faizi ile birlikte ödenmesini talep hakları
                dahil) mevcut ve saklıdır. ALICI’nın temerrüdü durumunda aylık % 5 oranında temerrüt
                faizi tatbik edilir.
              </p>
              <p>
                <strong>MADDE 5 – GENEL HÜKÜMLER</strong>
              </p>
              <p>
                5.1. ALICI, WEB SİTESİ ‘nde gösterilen ürünlerin temel nitelikleri, satış fiyatı ve
                ödeme şekli ile teslimata ilişkin ön bilgileri okuyup bilgi sahibi olduğunu ve
                elektronik ortamda satış için gerekli teyidi verdiğini kabul eder.
              </p>
              <p>
                5.2. ALICI; bu sözleşmeyi elektronik ortamda teyit etmekle, mesafeli sözleşmelerin
                akdinden önce, Satıcı tarafından Tüketici ‘ye verilmesi gereken adres, siparişi
                verilen ürünlere ait temel özellikler, ürünlerin vergiler dahil fiyatı, ödeme ve
                teslimat bilgilerini de doğru ve eksiksiz olarak edindiğini teyit etmiş olur.
              </p>
              <p>
                <strong>MADDE 6- GENEL HÜKÜMLER</strong>
              </p>
              <p>
                6.1- ALICI, www.eryagroups.com internet sitesinde sözleşme konusu ürün veya hizmetin
                temel nitelikleri, satış fiyatı ve ödeme şekli ile teslimata ilişkin ön bilgileri
                okuyup bilgi sahibi olduğunu ve elektronik ortamda gerekli teyidi verdiğini beyan
                eder.
              </p>
              <p>
                6.2- Sözleşme konusu ürün veya hizmet, yasal 3 günlük süreyi aşmamak koşulu ile her
                bir ürün için ALICI’nın yerleşim yerinin uzaklığına bağlı olarak internet sitesinde
                ön bilgiler içinde açıklanan süre içinde ALICI veya gösterdiği adresteki
                kişi/kuruluşa teslim edilir. Alınan eğitimlere erişim anında sağlanarak herhangi bir
                ürün tarafınıza kargolanmaz.
              </p>
              <p>
                6.3- Sözleşme konusu ürün, ALICI’dan başka bir kişi/kuruluşa teslim edilecek ise,
                teslim edilecek kişi/kuruluşun teslimatı kabul etmemesininden SATICI sorumlu
                tutulamaz.
              </p>
              <p>
                6.4- SATICI, sözleşme konusu ürünün sağlam, eksiksiz, siparişte belirtilen
                niteliklere uygun ve varsa garanti belgeleri ve kullanım klavuzları ile teslim
                edilmesinden sorumludur.
              </p>
              <p>
                6.5- Sözleşme konusu ürünün teslimatı için işbu sözleşmenin imzalı nüshasının
                SATICI’ya ulaştırılmış olması ve bedelinin ALICI’nın tercih ettiği ödeme şekli ile
                ödenmiş olması şarttır. Herhangi bir nedenle ürün bedeli ödenmez veya banka
                kayıtlarında iptal edilir ise, SATICI ürünün teslimi yükümlülüğünden kurtulmuş kabul
                edilir.
              </p>
              <p>
                6.6- Ürünün tesliminden sonra ALICI’ya ait kredi kartının ALICI’nın kusurundan
                kaynaklanmayan bir şekilde yetkisiz kişilerce haksız veya hukuka aykırı olarak
                kullanılması nedeni ile ilgili banka veya finans kuruluşun ürün bedelini SATICI’ya
                ödememesi halinde, ALICI’nın kendisine teslim edilmiş olması kaydıyla ürünün 3 gün
                içinde SATICI’ya gönderilmesi zorunludur. Bu takdirde nakliye giderleri ALICI’ya
                aittir. Eğitim platformumuz üzerinden satın alınan eğitimlerde herhangi bir iade
                şansı yoktur.
              </p>
              <p>
                6.7- SATICI mücbir sebepler veya nakliyeyi engelleyen hava muhalefeti, ulaşımın
                kesilmesi gibi olağanüstü durumlar nedeni ile sözleşme konusu ürünü süresi içinde
                teslim edemez ise, durumu ALICI’ya bildirmekle yükümlüdür. Bu takdirde ALICI
                siparişin iptal edilmesini, sözleşme konusu ürünün varsa emsali ile
                değiştirilmesini, ve/veya teslimat süresinin engelleyici durumun ortadan kalkmasına
                kadar ertelenmesi haklarından birini kullanabilir. ALICI’nın siparişi iptal etmesi
                halinde ödediği tutar 10 gün içinde kendisine nakten ve defaten ödenir. Eğitim satın
                alımlarında bu kullanıcıya erişim bilgilerinin 1 haftadan uzun süre boyunca
                gönderilememesi durumunda iptal edilerek iadesi gerçekleştirilir.
              </p>
              <p>
                <strong>MADDE 7- CAYMA HAKKI</strong>
              </p>
              <p>
                ALICI, sözleşme konusu ürün veya hizmetin kendisine veya gösterdiği adresteki
                kişi/kuruluşa tesliminden itibaren eğer herhangi bir kullanım durumu söz konusu
                değilse 3 gün içinde cayma hakkına sahiptir. Fakat, canlı eğitim bağlantıları
                yapılmışsa girilen bağlantı sayısı ve anlatılan eğitim içeriği oranında ödenecek
                tutar yüzdelik olarak hesaplanıp kesintili bir şekilde ödeme yapılacaktır. Cayma
                hakkının kullanılması halinde, 3. kişiye veya ALICI’ya teslim edilen ürünün
                SATICI’ya ulaştığına dair fatura aslının iadesi zorunludur. Bu belgelerin ulaşmasını
                takip eden 15 gün içinde ürün veya hizmet bedeli ALICI’ya iade edilir. Fatura aslı
                gönderilmez ise KDV ve varsa sair yasal yükümlülükler iade edilemez. 
              </p>
              <p>
                <strong>MADDE 8- CAYMA HAKKI KULLANILAMAYACAK ÜRÜN VE HİZMETLER</strong>
              </p>
              <p>
                Niteliği itibarıyla iade edilemeyecek ürünler, tek kullanımlık ürünler,
                kopyalanabilir yazılım ve programlar, hızlı bozulan veya son kullanım tarihi geçen
                ürünler için cayma hakkı kullanılamaz. Aşağıdaki ürünlerde cayma hakkının
                kullanılması, ürünün ambalajının açılmamış, bozulmamış ve ürünün kullanılmamış
                olması şartına bağlıdır.
                <p>– Her türlü yazılım ve programlar</p>
                <p>– DVD, VCD, CD ve kasetler</p>
                <p>– Online erişim hakkı verilmiş ve izlenmiş video setleri</p>
                <p>
                  -Online E-ticaret platformlarına ait mağaza açılış işlemleri ve teknik dökümanları
                </p>
              </p>
              <p>
                <strong>MADDE 9- YETKİLİ MAHKEME</strong>
              </p>
              <p>
                9.1.Bu Sözleşme’den ve/veya uygulanmasından doğabilecek her türlü uyuşmazlığın
                çözümünde SATICI kayıtları (bilgisayar-ses kayıtları gibi manyetik ortamdaki
                kayıtlar dahil) kesin delil oluşturur; Sanayi ve Ticaret Bakanlığınca ilan edilen
                değere kadar Tüketici Hakem Heyetleri, aşan durumlarda ALICI’nın ve SATICI’nın
                yerleşim yerindeki Tüketici Mahkemeleri ve İcra Müdürlükleri yetkilidir.
              </p>
              <p>
                9.2. ALICI bu Sözleşme’de ve ayrılmaz parçasını oluşturan sipariş formunda yazılı
                tüm koşulları ve açıklamaları okuduğunu, satış koşullarının ve sair tüm ön bilgileri
                aldığını, incelediğini ve tamamını kabul ettiğini beyan, kabul ve taahhüt eder.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default SalesContract;
