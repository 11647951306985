import React from 'react';
import SectionsTitle from '../components/common/SectionsTitle';
import Testimonial from '../components/sliders/Testimonial';

export default function TestimonialSection({
  referenceImg,
  secTitle,
  secSubtitle,
  sliders,
  imgshapes
}) {
  return (
    <section className='testimonial-wrapper '>
      <div className='text-center'>
        <SectionsTitle title='Danışan Yorumları' subtitle='Erya Groups Ailesi' />
      </div>
      <Testimonial referenceImg={referenceImg} slideitems={sliders} imgshapes={imgshapes} />
    </section>
  );
}
