import React from 'react';
import { useSelector } from 'react-redux';
import { selectCartTotal } from '../../../redux/Cart/cart.selectors';
import { createStructuredSelector } from 'reselect';
const mapState = createStructuredSelector({
  total: selectCartTotal
});

export default function CheckOutTotal({ value }) {
  const { total } = useSelector(mapState);
  const tax = total * 0.18;
  const subTotal = total - tax;
  return (
    <>
      <tbody className='subtotals'>
        <tr>
          <th>Ara Toplam</th>
          <td>₺{subTotal}</td>
        </tr>
        <tr>
          <th>Vergi</th>
          <td>₺{tax}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th>Toplam</th>
          <td>₺{total}</td>
        </tr>
      </tfoot>
    </>
  );
}
