import React from 'react';
import logo from '../../assets/images/9.png';
import { Link } from 'react-router-dom';

export default function HeaderError() {
  return (
    <div className='header-error-wrapper d-flex'>
      <div className='left'>
        <Link to='/' className='logo'>
          <img src={logo} alt='Logo' />
        </Link>
      </div>
    </div>
  );
}
