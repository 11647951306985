import React from 'react';
import SectionsTitle from '../common/SectionsTitle';
import { FiPhoneCall } from 'react-icons/fi';
import { MdMailOutline } from 'react-icons/md';
import CallImg from '../../assets/images/eryacta.jpg';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import contactService from '../../fetchServices/ContactService';
import { message } from 'antd';
export default function CtaForm() {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('İsim ve Soyisim zorunludur'),
    subject: Yup.string().required('Konu Zorunludur.'),
    email: Yup.string().required('Email Zorunludur.').email('Email Zorunludur.'),
    phone: Yup.string()
      .matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, 'Geçersiz Numara')
      .min(10, 'Telefon Numarası 10 Haneli olmalıdır')
      .max(10, 'Telefon Numarası 10 Haneli olmalıdır')
      .required('Telefon Numarası gereklidir')
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  function onSubmit(data) {
    const params = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      subject: data.subject
    };
    contactService
      .create(params)
      .then(response => {
        message.success(
          'Bilgileriniz Bize Ulaştı ! En Yakın Zaman da Uzman Bir Ekip Arkadaşımız Sizi Arayacak.'
        );
      })
      .catch(err => {
        message.error('Beklenmeyen Bir Hata Oluştu');
      });
    return false;
  }
  return (
    <section className='ctaform-wrapper'>
      <div className='container'>
        <div className='ctaform-grid'>
          <div className='ctaform-left'>
            <SectionsTitle title='Sizi Arayalım.' subtitle='Erya Groups Eğitim & Danışmanlık' />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='form-grid'>
                <input
                  placeholder='Adınız Soyadınız'
                  name='name'
                  type='text'
                  {...register('name')}
                  className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                />
                <div className='invalid-feedback'>{errors.name?.message}</div>
                <input
                  placeholder='Mail Adresiniz'
                  name='email'
                  type='text'
                  {...register('email')}
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                />
                <div className='invalid-feedback'>{errors.email?.message}</div>

                <input
                  name='phone'
                  type='number'
                  placeholder='Telefon Numaranızı başında 0 olmadan giriniz'
                  {...register('phone')}
                  className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                />
                <div className='invalid-feedback'>{errors.phone?.message}</div>

                <input
                  placeholder='Konu'
                  name='subject'
                  type='text'
                  {...register('subject')}
                  className={`form-control ${errors.subject ? 'is-invalid' : ''}`}
                />
                <div className='invalid-feedback'>{errors.subject?.message}</div>
              </div>

              <p>
                * Formu göndermeniz halinde tarafınıza yazılı ya da sözlü iletişime geçilmesi için
                onay vermiş sayılırsınız.
              </p>
              <p />
              <button type='submit' value='Register Account' className='theme-button'>
                Beni Arayın
              </button>
            </form>
          </div>
          <div className='ctaform-right'>
            <div className='ctaform-contact-info-grid text-center'>
              <img width='100%' height='100%' src={CallImg} alt='conctact' />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
