import React from 'react';
import Navbar from '../components/common/Navbar';
import Breadcrumb from '../components/common/Breadcrumb';
import About from '../components/about/About';
import ServiceSection2 from '../sections/services/ServiceSection2';
import SimpleTab from '../components/tabs/SimpleTab';
import CounterUp from '../sections/CounterUp';
import Team from '../components/team/Team';
import Clients from '../sections/Clients';
import Service3clmns from '../components/services/Service3clmns';
import CtaForm from '../components/other/CtaForm';
import Footer from '../components/other/Footer';
import sectiondata from '../store/store';
import ProfileForm from '../components/other/ProfileForm';

export default function Profile_Page() {
  return (
    <main className='about-page'>
      {/* Header */}
      <header className='header-area'>
        <Navbar />
      </header>

      {/* Breadcrumb */}
      <Breadcrumb title='Hesabım' />

      <ProfileForm />
      {/* Footer */}
      <Footer />
    </main>
  );
}
