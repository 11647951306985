import React from 'react';
import { FaGoogle, FaFacebookF, FaTwitter } from 'react-icons/fa';
import { message } from 'antd';
import { FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import userService from '../../fetchServices/UserServics';
import { useHistory } from 'react-router-dom';

export default function SignUpForm() {
  let history = useHistory();
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email Zorunludur.').email('Email Zorunludur.'),
    password: Yup.string()
      .min(6, 'Şifreniz en az 6 karakterli olmalıdır')
      .required('Şifre gereklidir')
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data) {
    const params = {
      password: data.password,
      email: data.email
    };
    userService
      .login(params)
      .then(response => {
        localStorage.setItem('USER', JSON.stringify(response));
        localStorage.setItem('AUTH_TOKEN', response?.accessToken);
        history.push('/');
        window.location.reload();
      })
      .catch(err => {
        message.error(`${err?.response?.data?.message}`);
      });
    return false;
  }

  return (
    <section className='signup-form-wrapper'>
      <div className='container'>
        <div className='signup-form-box'>
          <form className='signup-form' onSubmit={handleSubmit(onSubmit)}>
            <input
              placeholder='Mail Adresiniz'
              name='email'
              type='text'
              {...register('email')}
              className={`form-control ${errors.email ? 'is-invalid' : ''}`}
            />
            <div className='invalid-feedback'>{errors.email?.message}</div>

            <input
              name='password'
              type='password'
              placeholder='Şifre'
              {...register('password')}
              className={`form-control ${errors.password ? 'is-invalid' : ''}`}
            />
            <div className='invalid-feedback'>{errors.password?.message}</div>
            <div className='checkboxes d-flex'>
              <label className='privacy-policy'></label>
              <Link to='/forgot-password'>Şifre Mi Unuttum ?</Link>
            </div>
            <button type='submit' value='Register Account' className='theme-button'>
              Giriş Yap <FiChevronRight className='icon' />
            </button>
          </form>
          <p className='already-account'>
            Hesabın Yok Mu ? <Link to='/sign-up'>Kayıt Ol</Link>
          </p>
        </div>
      </div>
    </section>
  );
}
