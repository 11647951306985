import React from 'react';
import Navbar from '../components/common/Navbar';
import Breadcrumb from '../components/common/Breadcrumb';
import About from '../components/about/About';
import ServiceSection2 from '../sections/services/ServiceSection2';
import SimpleTab from '../components/tabs/SimpleTab';
import CounterUp from '../sections/CounterUp';
import Team from '../components/team/Team';
import Clients from '../sections/Clients';
import Service3clmns from '../components/services/Service3clmns';
import CtaForm from '../components/other/CtaForm';
import Footer from '../components/other/Footer';
import sectiondata from '../store/store';

export default function About_Page() {
  return (
    <main className='about-page'>
      {/* Header */}
      <header className='header-area'>
        <Navbar />
      </header>

      {/* Breadcrumb */}
      <Breadcrumb title='Hakkımızda' />

      {/* About 2 */}
      <About />

      <Service3clmns />
      {/* Services */}

      {/* Why Choose Us */}

      <Team
        secTitle={sectiondata.Teammemberdata.secTitle}
        secSubtitle={sectiondata.Teammemberdata.secSubtitle}
        teammembers={sectiondata.Teammemberdata.teammembers}
      />

      {/* Clients Logo */}
      <div className='form-white-bg'>
        <CtaForm />
      </div>

      {/* Footer */}
      <Footer />
    </main>
  );
}
