import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SectionsTitle from '../common/SectionsTitle';
import { FiChevronRight, FiUserCheck, FiTarget } from 'react-icons/fi';
import { TiLightbulb } from 'react-icons/ti';
import img1 from '../../assets/images/image1.jpeg';
import img2 from '../../assets/images/image2.jpeg';
import img3 from '../../assets/images/image3.jpeg';
import ModalVideo from 'react-modal-video';
import Progressbar from '../other/Progressbar';

export default class About extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  aboutstate = {
    supports: [
      {
        icon: <FiUserCheck />,
        title: '24/7 Online Destek'
      },
      {
        icon: <FiTarget />,
        title: '3 Yılı Aşkın Deneyimli Kadro'
      },
      {
        icon: <TiLightbulb />,
        title: 'Bünyemize Ait Geliştirici Ekip'
      }
    ]
  };
  aboutrightimg = {
    images: [
      {
        img: img1
      },
      {
        img: img2
      },
      {
        img: img3
      }
    ]
  };
  render() {
    return (
      <section className='about-wrapper'>
        <div className='container'>
          <div className='about-grid-wrap'>
            <div className='about-left'>
              <SectionsTitle
                title='E-ticaret çözümleri sunun firmamıza hoşgeldiniz.'
                subtitle='Hakkımızda Bilmeniz Gerekenler'
              />
              <p className='about-left-desc'>
                2020 yılında faaliyete geçen ER-YA Group Eğitim ve Danışmanlık, yeni bir firma
                olmasına rağmen sektörde kısa sürede adından söz ettirmiş ve başarılı işler
                gerçekleştirmiştir. Başarısını büyük ölçüde özgün bakış açısına, online eğitim ve
                danışmanlık kavramına farklı bir boyut kazandırmış olmasına borçludur. Faaliyete
                geçtiği ilk günden itibaren, en iyi hizmeti verebilmek için eğitim ve danışmanlık
                çalışmalarına alanında uzman kişilerle devam etmektedir.
              </p>
              <div className='about-iconbox-grid'>
                {this.aboutstate.supports.map((item, index) => {
                  return (
                    <div className='about-iconbox' key={index}>
                      <span className='about-iconbox-icon'>{item.icon}</span>
                      <h3 className='about-iconbox-title'>{item.title}</h3>
                    </div>
                  );
                })}
              </div>
            </div>
            {/*   <div className='about-right'>
              {this.aboutrightimg.images.map((img, index) => {
                return (
                  <img
                    key={index}
                    className={'about_img_' + index}
                    src={img.img}
                    alt={'About Image ' + index}
                  />
                );
              })}
             <ModalVideo
                channel='youtube'
                isOpen={this.state.isOpen}
                videoId='t3tsMEhlvwM'
                onClose={() => this.setState({ isOpen: false })}
              />
              <Link to='#' className='video-btn' onClick={this.openModal}>
                Play
             </Link>
            </div>*/}
          </div>
        </div>
      </section>
    );
  }
}
